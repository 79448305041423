export const GetWipKey = (key: string, user: string = '') => `resource-planner-${user}-${key}`;

export const WipViewOptionsStateKey = 'wip-view-options';

export const WipSelectedFilterListStateKey = 'wip-selected-filter-list';

export const WipInvalidateOldQueryOnViewOptionChange = 'wip-invalidate-old-query';

export const GetWipSavedViewsKey = (user?: string) => GetWipKey('saved-views', user);

export const GetWipReturningUserKey = (user?: string) => GetWipKey('returning-user', user);
