import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { toCamel } from 'src/utils/object';
import { getWipViewOptions } from 'src/mocks/reports/wip';
import { IWipModelResult } from '../types';

const WIP_VIEW_OPTIONS_URL_PATH = '/api/v2/wip/view-options';
export const WIP_VIEW_OPTIONS_MSW_STATUS_KEY = 'GetWipViewOptionsAPI';

export const WIP_VIEW_OPTIONS_KEY = 'WIP_VIEW_OPTIONS';

const wipViewOptionsFetch = (): Promise<IWipModelResult> =>
  getFetch({
    path: WIP_VIEW_OPTIONS_URL_PATH,
    key: WIP_VIEW_OPTIONS_MSW_STATUS_KEY,
  });

export const useGetWipViewOptions = () => {
  const {
    data: { model: { entities = [] } = {} } = {},
    isError,
    ...restProps
  } = useQuery([WIP_VIEW_OPTIONS_KEY], wipViewOptionsFetch, {
    retry: 0,
  });

  if (isError) {
    // For testing, to be remove
    const { model } = toCamel(getWipViewOptions) as IWipModelResult;
    return {
      fields: model.entities.map(({ properties: { name = '', options = [] } = {} }) => ({
        name,
        value: options.find(({ selected }) => selected)?.value,
        options: options.map(({ value }) => ({
          value,
        })),
      })),
      isError: false,
      ...restProps,
    };
  }

  return {
    fields: entities.map(({ properties: { name = '', options = [] } = {} }) => ({
      name,
      value: options.find(({ selected }) => selected)?.value,
      options: options.map(({ value }) => ({
        value,
      })),
    })),
    isError,
    ...restProps,
  };
};
