// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { v4 as uuidv4 } from 'uuid';

// Expected data with separate row
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const items: any = [
  {
    id: 1,
    hierarchy: ['TimeLog A/S'],
    customer: 'TimeLog A/S',
    customerNo: 'K210012',
    primoBar: 10000,
    primoInvoiced: 11000,
    primoAdjustment: 9000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 2,
    hierarchy: ['TimeLog A/S', 'Project name'],
    customer: 'Project name',
    customerNo: 'P17.0009',
    primoBar: '',
    primoInvoiced: '',
    primoAdjustment: '',
    primoWip: '',
    estNbs: '',
    bar: '',
    invoiced: '',
    adjustment: '',
    wip: '',
    ultimoBar: '',
    ultimoInvoiced: '',
    ultimoAdjustment: '',
    ultimoWip: '',
  },
  {
    id: 3,
    hierarchy: ['TimeLog A/S', 'Project name', 'Contract Name'],
    customer: 'Contract Name',
    customerNo: 'K210012',
    primoBar: '',
    primoInvoiced: '',
    primoAdjustment: '',
    primoWip: '',
    estNbs: '',
    bar: '',
    invoiced: '',
    adjustment: '',
    wip: '',
    ultimoBar: '',
    ultimoInvoiced: '',
    ultimoAdjustment: '',
    ultimoWip: '',
  },
  {
    id: 8,
    hierarchy: ['TimeLog A/S', 'Project name 2'],
    customer: 'Project name 2',
    customerNo: 'P23.0009',
    primoBar: '',
    primoInvoiced: '',
    primoAdjustment: '',
    primoWip: '',
    estNbs: '',
    bar: '',
    invoiced: '',
    adjustment: '',
    wip: '',
    ultimoBar: '',
    ultimoInvoiced: '',
    ultimoAdjustment: '',
    ultimoWip: '',
  },
  {
    id: 9,
    hierarchy: ['TimeLog A/S', 'Project name 2', 'Contract Name 2'],
    customer: 'Contract Name 2',
    customerNo: 'K210012',
    primoBar: '',
    primoInvoiced: '',
    primoAdjustment: '',
    primoWip: '',
    estNbs: '',
    bar: '',
    invoiced: '',
    adjustment: '',
    wip: '',
    ultimoBar: '',
    ultimoInvoiced: '',
    ultimoAdjustment: '',
    ultimoWip: '',
  },
  {
    id: 4,
    hierarchy: ['TimeLog A/S', 'Project name', 'Contract Name', 'Own work'],
    customer: 'Own work',
    customerNo: 'K210012',
    primoBar: 2000,
    primoInvoiced: 3000,
    primoAdjustment: 1000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 1000,
    ultimoWip: 11000,
  },
  {
    id: 5,
    hierarchy: ['TimeLog A/S', 'Project name', 'Contract Name', 'External work'],
    customer: 'External work',
    customerNo: 'K210012',
    primoBar: 2000,
    primoInvoiced: 3000,
    primoAdjustment: 1000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 6,
    hierarchy: ['TimeLog A/S', 'Project name', 'Contract Name', 'Goods'],
    customer: 'Goods',
    customerNo: 'K210012',
    primoBar: 2000,
    primoInvoiced: 3000,
    primoAdjustment: 1000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 7,
    hierarchy: ['TimeLog A/S', 'Project name', 'Contract Name', 'Expenses'],
    customer: 'Expenses',
    customerNo: 'K210013',
    primoBar: 4000,
    primoInvoiced: 2000,
    primoAdjustment: 6000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 10,
    hierarchy: ['TimeLog A/S', 'Project name 2', 'Contract Name 2', 'External work'],
    customer: 'External work',
    customerNo: 'K210012',
    primoBar: 2000,
    primoInvoiced: 3000,
    primoAdjustment: 1000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 11,
    hierarchy: ['TimeLog A/S', 'Project name 2', 'Contract Name 2', 'Goods'],
    customer: 'Goods',
    customerNo: 'K210012',
    primoBar: 2000,
    primoInvoiced: 3000,
    primoAdjustment: 1000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
  {
    id: 12,
    hierarchy: ['TimeLog A/S', 'Project name 2', 'Contract Name 2', 'Expenses'],
    customer: 'Expenses',
    customerNo: 'K210013',
    primoBar: 4000,
    primoInvoiced: 2000,
    primoAdjustment: 6000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
];

// Expected data with inner children
const itemsWithChild: any = [
  {
    id: uuidv4(),
    customer: 'TimeLog A/S',
    customerNo: 'K210012',
    contractTotal: 200000,
    endDate: '',
    children: [
      {
        id: uuidv4(),
        customer: 'TIM - Epic 22',
        customerNo: 'P17.0009',
        contractTotal: 6250,
        endDate: '30-06-2024',
        children: [
          {
            id: uuidv4(),
            customer: 'Contract Name',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            children: [
              {
                id: uuidv4(),
                customer: 'Own work',
                customerNo: 'K210012',
                contractTotal: 6250,
                endDate: '',
                primoBar: 2000,
                primoInvoiced: 3000,
                primoAdjustment: 1000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 1000,
                ultimoWip: 11000,
              },
              {
                id: uuidv4(),
                customer: 'External work',
                customerNo: 'K210012',
                contractTotal: 6250,
                endDate: '',
                primoBar: 2000,
                primoInvoiced: 3000,
                primoAdjustment: 1000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
              {
                id: uuidv4(),
                customer: 'Goods',
                customerNo: 'K210012',
                contractTotal: 6250,
                endDate: '',
                primoBar: 2000,
                primoInvoiced: 3000,
                primoAdjustment: 1000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
              {
                id: uuidv4(),
                customer: 'Expenses',
                customerNo: 'K210013',
                contractTotal: 6250,
                endDate: '',
                primoBar: 4000,
                primoInvoiced: 2000,
                primoAdjustment: 6000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
            ],
            primoBar: '',
            primoInvoiced: '',
            primoAdjustment: '',
            primoWip: '',
            estNbs: '',
            bar: '',
            invoiced: '',
            adjustment: '',
            wip: '',
            ultimoBar: '',
            ultimoInvoiced: '',
            ultimoAdjustment: '',
            ultimoWip: '',
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
      {
        id: uuidv4(),
        customer: 'Epic - search project',
        customerNo: 'P23.0009',
        contractTotal: 321000,
        endDate: '22-08-2024',
        children: [
          {
            id: uuidv4(),
            customer: 'Contract Name 2',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            children: [
              {
                id: uuidv4(),
                customer: 'External work',
                customerNo: 'K210012',
                contractTotal: 6250,
                endDate: '',
                primoBar: 2000,
                primoInvoiced: 3000,
                primoAdjustment: 1000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
              {
                id: uuidv4(),
                customer: 'Goods',
                customerNo: 'K210012',
                contractTotal: 6250,
                endDate: '',
                primoBar: 2000,
                primoInvoiced: 3000,
                primoAdjustment: 1000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
              {
                id: 12,
                customer: 'Expenses',
                customerNo: 'K210013',
                contractTotal: 6250,
                endDate: '22-02-2024',
                primoBar: 4000,
                primoInvoiced: 2000,
                primoAdjustment: 6000,
                primoWip: 11000,
                estNbs: 10000,
                bar: 1000,
                invoiced: 10000,
                adjustment: 9000,
                wip: 9000,
                ultimoBar: 9000,
                ultimoInvoiced: 10000,
                ultimoAdjustment: 11000,
                ultimoWip: 11000,
              },
            ],
            primoBar: '',
            primoInvoiced: '',
            primoAdjustment: '',
            primoWip: '',
            estNbs: '',
            bar: '',
            invoiced: '',
            adjustment: '',
            wip: '',
            ultimoBar: '',
            ultimoInvoiced: '',
            ultimoAdjustment: '',
            ultimoWip: '',
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
    ],
    primoBar: 10000,
    primoInvoiced: 11000,
    primoAdjustment: 9000,
    primoWip: 11000,
    estNbs: 10000,
    bar: 1000,
    invoiced: 10000,
    adjustment: 9000,
    wip: 9000,
    ultimoBar: 9000,
    ultimoInvoiced: 10000,
    ultimoAdjustment: 11000,
    ultimoWip: 11000,
  },
];

export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'Wip',
      viewOptions: {
        'grouped-by': 'group-by-customer',
        'reporting-level': 'project',
        'revenue-in-wip': 'bar',
        'period-starts-at': '2023-03-01',
        'period-ends-at': '2023-09-30',
      },
      periods: [
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Month',
          identifier: '2023Mar',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Month',
          identifier: '2023Apr',
        },
        {
          startsAt: '2023-05-01T00:00:00',
          endsAt: '2023-05-31T00:00:00',
          type: 'Month',
          identifier: '2023May',
        },
        {
          startsAt: '2023-06-01T00:00:00',
          endsAt: '2023-06-30T00:00:00',
          type: 'Month',
          identifier: '2023Jun',
          isCurrentMonth: true,
        },
        {
          startsAt: '2023-07-01T00:00:00',
          endsAt: '2023-07-31T00:00:00',
          type: 'Month',
          identifier: '2023Jul',
        },
        {
          startsAt: '2023-08-01T00:00:00',
          endsAt: '2023-08-31T00:00:00',
          type: 'Month',
          identifier: '2023Aug',
        },
        {
          startsAt: '2023-09-01T00:00:00',
          endsAt: '2023-09-30T00:00:00',
          type: 'Month',
          identifier: '2023Sep',
        },
      ],
      children: itemsWithChild,
      summations: [
        {
          id: 'TOTAL',
          // hierarchy: ['TimeLog A/S', 'Project name 3', 'Contract Name', 'Expenses'],
          customer: '',
          customerNo: '',
          contractTotal: 200000,
          primoBar: 4000,
          primoInvoiced: 2000,
          primoAdjustment: 6000,
          primoWip: 11000,
          estNbs: 10000,
          bar: 1000,
          invoiced: 10000,
          adjustment: 9000,
          wip: 9000,
          ultimoBar: 9000,
          ultimoInvoiced: 10000,
          ultimoAdjustment: 11000,
          ultimoWip: 11000,
        },
      ],
    },
    links: [{ href: '/wip', rel: 'self' }],
  },
  DeterministicHash: 2699400,
  StatusCode: 200,
};
