export const GetRPKey = (key: string, user: string = '') => `resource-planner-${user}-${key}`;

export const RPProjectViewInitialExpandedRowsStateKey =
  'resource-planner-project-view-row-expand-state';

export const RPEmployeeViewInitialExpandedRowsStateKey =
  'resource-planner-employee-view-row-expand-state';

export const RPViewOptionsStateKey = 'resource-planner-view-options';

export const RPSelectedFilterListStateKey = 'resource-planner-selected-filter-list';

export const RPInvalidateOldQueryOnViewOptionChange = 'resource-planner-invalidate-old-query';

export const GetRPSavedViewsKey = (user?: string) => GetRPKey('saved-views', user);

export const GetRPReturningUserKey = (user?: string) => GetRPKey('returning-user', user);
