import { ContentCopyRounded, Delete, Edit } from '@mui/icons-material';
import {
  addMonths,
  addQuarters,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  parse,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { safeParseJson } from 'src/utils/object';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import { useGetWipViewOptions } from 'src/apis/reports/wipAPI';
import { ISavedView } from 'src/apis/reports/wipAPI/savedViewAPI';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useGetFirstName, useGetUserId } from 'src/apis/usersAPI/meAPI';
import { ToastifyAlert } from 'src/components/mui-components';
import { IFilterProps, IMenuItem, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { CustomerTable, PeriodPicker, ProjectTable } from './components';
import { ViewFormDialog, ViewTextDialog } from './components/SavedViewDialog';
import { INITIAL_SAVED_VIEW, SNACKBAR_CONFIG } from './constants';
import { sortViews } from './helper/sortViews';
import {
  WipSelectedFilterListStateKey,
  WipViewOptionsStateKey,
  GetWipReturningUserKey,
  GetWipSavedViewsKey,
} from './localStorageKeys';
import {
  ISavedViewForm,
  SnackbarMode,
  TFormDialogMode,
  TSavedViewAction,
  TSnackbarKeys,
  TTextDialogMode,
  TViewOptionKey,
  TViewOptions,
  ViewType,
  IOptionsChangeParameters,
} from './types';

export const Wip = () => {
  const { t } = useTranslation('savedView');

  const { fields: initialFields } = useGetWipViewOptions();

  const qc = useQueryClient();
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { shortNameWithoutUrl } = useGetShortNameWithoutUrl();

  const localStorageReturningUser =
    localStorage.getItem(GetWipReturningUserKey(shortNameWithoutUrl)) ?? '';
  const returningUser = localStorageReturningUser === 'true';

  const localStorageSavedViews =
    localStorage.getItem(GetWipSavedViewsKey(shortNameWithoutUrl)) ?? '';
  const [savedViews, setSavedViews] = useState<ISavedView[]>([]);

  const sortedViews = useMemo((): ISavedView[] => sortViews(savedViews), [savedViews]);
  const [activeView, setActiveView] = useState<string>();

  const savedViewOptions = sortedViews?.find((v) => v.id === activeView)?.viewOptions;
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(
    savedViewOptions ?? undefined,
  );
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );
  const { firstName } = useGetFirstName();

  const employeeFilter = flattenedFilterItemsList.find(({ name }) => name === 'Employee');

  const { dropdownList } = useGetDropdownWithSearchAPI({
    key: `filterInput${employeeFilter?.id}`,
    MSWKey: `FILTER_INPUT_${employeeFilter?.id}`,
    path: `/${employeeFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: firstName,
    enabled: !!employeeFilter?.contentUrl && !returningUser,
  });
  const { userId } = useGetUserId();
  const currentUserOption = dropdownList?.find((item) => item.value === String(userId));

  const initialFilter = useMemo(() => {
    if (!currentUserOption || !employeeFilter) {
      return undefined;
    }

    return {
      Employee: {
        label: employeeFilter.name,
        values: [
          {
            label: currentUserOption.label,
            value: currentUserOption.value,
          },
        ],
        contentUrl: employeeFilter.contentUrl,
        childFilters: employeeFilter.childFilters,
        parentFilters: employeeFilter.parentFilters,
        isInclude: true,
      },
    };
  }, [currentUserOption, employeeFilter]);

  const getStartDateForViewOptions = () => new Date();
  const [initialDateEnd, setInitialDateEnd] = useState(getStartDateForViewOptions());
  const [initialDateStart, setInitialDateStart] = useState(getStartDateForViewOptions());

  const getEndDateForViewOptions = useCallback(() => {
    const period = sortedViews.find((v) => v.id === activeView)?.period;

    switch (period) {
      case 'this_week':
        return endOfWeek(new Date(), { weekStartsOn: 1 });

      case 'this_and_next_week':
        return endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 });

      case 'this_month':
        return endOfMonth(new Date());

      case 'this_and_next_month':
        return endOfMonth(addMonths(new Date(), 1));

      case 'this_quarter':
        return endOfQuarter(new Date());

      case 'this_and_next_quarter':
        return endOfQuarter(addQuarters(new Date(), 1));

      case 'this_year':
        return endOfYear(new Date());

      case 'this_and_next_three_weeks':
      default:
        return endOfWeek(addWeeks(new Date(), 3), { weekStartsOn: 1 });
    }
  }, [activeView, sortedViews]);

  const onViewOptionsChange = (items: IOptionsChangeParameters[]) => {
    const options = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }

      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      return { [item.name]: item.value };
    });
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const fields = useMemo(
    () =>
      initialFields.map((field) => ({
        ...field,
        value: changedViewOptions?.[field.name as TViewOptionKey] ?? field.value,
        options: field.options.map((option) => ({
          ...option,
        })),
      })),
    [changedViewOptions, initialFields],
  );

  const groupedBy = useMemo(() => {
    const groupedByViewOption = fields.find(({ name }) => name === 'grouped-by');
    return groupedByViewOption?.value as ViewType;
  }, [fields]);

  useEffect(() => {
    localStorage.setItem(WipViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const selectedViewOptions = useMemo(
    () =>
      fields.reduce<TViewOptions>(
        (acc, { name, value }) => {
          if (value) {
            acc[name as keyof TViewOptions] = value;
          }
          return acc;
        },
        {
          'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
          'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
        },
      ),
    [fields, initialDateEnd, initialDateStart],
  );

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(WipSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  const removePeriodFromViewOptions = (viewOptions?: TViewOptions) => {
    if (!viewOptions) {
      return {};
    }
    const {
      'period-ends-at': periodEndsAt,
      'period-starts-at': periodStartsAt,
      ...rest
    } = viewOptions;
    return rest;
  };

  const selectedViewOptionsWithoutPeriod = useMemo(
    () => removePeriodFromViewOptions(selectedViewOptions),
    [selectedViewOptions],
  );

  const changedViewOptionsWithoutPeriod = useMemo(
    () => removePeriodFromViewOptions(changedViewOptions),
    [changedViewOptions],
  );

  // #Region SAVE VIEW
  const [showFormDialog, setShowFormDialog] = useState<TFormDialogMode | ''>('');
  const [showTextDialog, setShowTextDialog] = useState<TTextDialogMode | ''>('');

  const updateSavedViews = useCallback(
    (sv: ISavedView[]) => {
      setSavedViews(() => {
        localStorage.setItem(GetWipSavedViewsKey(shortNameWithoutUrl), JSON.stringify(sv));
        return sv;
      });
    },
    [shortNameWithoutUrl],
  );

  const displayToast = (type: SnackbarMode) => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};

    qc.invalidateQueries(['INTERNAL_MESSAGE']);
    toast.success(
      <ToastifyAlert
        title={t(title as TSnackbarKeys)}
        description={t(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  // form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    period: z.string().min(1, { message: 'Please select period' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      period: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      icon: <Edit />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('period', viewIndex.period, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('edit');
        }
      },
    },
    {
      id: 'duplicate',
      label: t('DuplicateViewMenuText'),
      icon: <ContentCopyRounded />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('period', viewIndex.period, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('duplicate');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      icon: <Delete />,
      action: () => {
        setShowTextDialog('delete');
      },
    },
  ];

  const updateActiveView = (viewId?: string) => {
    setActiveView(() => viewId);
  };

  const onSubmit = (data: ISavedViewForm) => {
    // set default to false for all views
    const viewData: ISavedView[] = savedViews.map((view) => ({
      ...view,
      isDefault: data.isDefault ? false : view.isDefault,
    }));

    const filters = localStorage.getItem(WipSelectedFilterListStateKey);
    const newID = uuidv4();
    let updatedData: ISavedView[];

    switch (showFormDialog) {
      case 'save':
        if (!filters) {
          return;
        }

        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            period: data.period,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: safeParseJson(filters),
            viewOptions: selectedViewOptionsWithoutPeriod,
          },
        ];
        displayToast('save');
        updateActiveView(newID);
        break;

      case 'edit':
        updatedData = viewData.map((view) => {
          if (view.id === activeView) {
            return {
              ...view,
              title: data.title,
              period: data.period,
              isDefault: data.isDefault,
            };
          }
          return view;
        });
        displayToast('edit');
        break;

      default:
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            period: data.period,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: filters ? safeParseJson(filters) : {},
            viewOptions: selectedViewOptionsWithoutPeriod,
          },
        ];
        displayToast('duplicate');
        updateActiveView(newID);
        break;
    }

    updateSavedViews(updatedData);
    form.reset();
  };

  const onDelete = () => {
    setShowTextDialog('');
    const updatedData = savedViews.filter((view) => view.id !== activeView);
    updateSavedViews(updatedData);
    updateActiveView(sortViews(updatedData)[0]?.id ?? '');
    displayToast('delete');
  };

  const onChangesUpdate = () => {
    const updatedSaveView = savedViews.map((v) => {
      const view = { ...v };
      if (view.id === activeView) {
        view.viewOptions = selectedViewOptionsWithoutPeriod;
        view.filters = safeParseJson(localStorage.getItem(WipSelectedFilterListStateKey) ?? '');
      }
      return view;
    });

    updateSavedViews(updatedSaveView);
    setShowTextDialog('');
    displayToast('changes');
  };

  const handleViewAction = (action: TSavedViewAction) => {
    if (action === 'saveAs') {
      form.reset();
      setShowFormDialog('save');
      return;
    }
    onChangesUpdate();
  };

  useEffect(() => {
    if (!localStorageSavedViews) {
      return;
    }
    setSavedViews(safeParseJson(localStorageSavedViews));
  }, [localStorageSavedViews]);

  useEffect(() => {
    if (activeView || !sortedViews?.length) {
      return;
    }
    updateActiveView(sortedViews?.[0]?.id);
  }, [activeView, sortedViews]);

  useEffect(() => {
    if (returningUser || savedViews.length || !initialFilter) {
      return;
    }
    localStorage.setItem(GetWipReturningUserKey(shortNameWithoutUrl), 'true');
    const id = uuidv4();
    updateSavedViews([
      {
        ...INITIAL_SAVED_VIEW,
        id,
        title: t('InitialViewTitle'),
        filters: initialFilter,
      },
    ]);
  }, [initialFilter, returningUser, savedViews.length, shortNameWithoutUrl, t, updateSavedViews]);

  useEffect(() => {
    if (!savedViewOptions) {
      return;
    }
    setChangedViewOptions(savedViewOptions);
  }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(WipViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  useEffect(() => {
    setShowFormDialog('');
    setShowTextDialog('');
    setInitialDateEnd(getEndDateForViewOptions());
  }, [getEndDateForViewOptions]);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={filterList.length <= 0} isError={isError}>
      <FilterLayout
        filterList={filterList}
        selectedFilterList={getSelectedFilterFromLocalStorage()}
        viewOptionsPeriodPicker={
          <PeriodPicker
            initialDateStart={initialDateStart}
            initialDateEnd={initialDateEnd}
            onChange={onViewOptionsChange}
          />
        }
        viewOptionsFields={fields}
        viewOptionsChange={onViewOptionsChange}
        localStorageNamePrefix="wip"
        allowSavedViews
        savedViewsList={sortedViews}
        activeView={activeView}
        menuItems={menuItems}
        handleViewAction={handleViewAction}
        setActiveView={updateActiveView}
        changedViewOptions={changedViewOptionsWithoutPeriod}
      >
        <>
          {groupedBy === 'group-by-customer' && (
            <CustomerTable selectedViewOptions={changedViewOptions} />
          )}
          {groupedBy === 'group-by-project' && (
            <ProjectTable selectedViewOptions={changedViewOptions} />
          )}

          {/* For form dialog */}
          {showFormDialog && (
            <ViewFormDialog
              showFormDialog={showFormDialog}
              setShowFormDialog={setShowFormDialog}
              onSubmit={onSubmit}
              form={form}
            />
          )}

          {/* For text alert dialog */}
          {showTextDialog && (
            <ViewTextDialog
              showTextDialog={showTextDialog}
              setShowTextDialog={setShowTextDialog}
              onDelete={onDelete}
            />
          )}
        </>
      </FilterLayout>
    </ResponseHandler>
  );
};

export default Wip;
