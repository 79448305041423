export default {
  model: {
    properties: {
      count: 3,
    },
    class: 'viewselector',
    entities: [
      {
        class: 'viewselector',
        properties: {
          name: 'grouped-by',
          options: [
            {
              value: 'group-by-customer',
              selected: true,
            },
            {
              value: 'group-by-project',
              selected: false,
            },
          ],
        },
      },
      {
        class: 'viewselector',
        properties: {
          name: 'reporting-level',
          options: [
            {
              value: 'project',
              selected: true,
            },
            {
              value: 'contract',
              selected: false,
            },
            {
              value: 'contract-revenue-budget',
              selected: false,
            },
          ],
        },
      },
      {
        class: 'viewselector',
        properties: {
          name: 'revenue-in-wip',
          options: [
            {
              value: 'bar',
              selected: true,
            },
            {
              value: 'bar-not-booked-tm',
              selected: false,
            },
          ],
        },
      },
    ],
    links: [
      {
        href: '/viewoptions',
        rel: 'self',
      },
    ],
  },
};
