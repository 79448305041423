import { useEffect, useRef, useState } from 'react';
import {
  useGetRevenueForecast,
  IRevenueForecastItem,
  IRevenueForecastPeriod,
} from 'src/apis/revenueForecastAPI';
import {
  DataGrid,
  dataGridCellBorderLeftThickClassName,
  dataGridCellBorderRightThickClassName,
  dataGridCellFontWeightMediumClassName,
  dataGridCellFontFamilyMonospaceClassName,
} from 'src/components/mui-components/DataGrid';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import {
  GridCellParams,
  GridColDef,
  GridColumnGroup,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
} from '@mui/x-data-grid-pro';
import { useFilterStore } from 'src/stores/FilterStore';
import { longMonthAndYearTranslated, shortMonthAndYearTranslated } from 'src/utils/date/month';
import { Typography } from 'src/components/mui-components';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { currencyFormatter } from 'src/utils/currency';
import { revenueForecastSelectedFilterListStateKey } from '../localStorageKeys';
import { Toolbar } from './Toolbar';

const slots = {
  toolbar: Toolbar,
};

export const Table = ({ selectedViewOptions }: { selectedViewOptions: any }) => {
  const { currentLanguage } = useGetCurrentLanguage();
  const { t } = useTranslation('revenueForecast');
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(revenueForecastSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const { isLoading, isSuccess, isError, isEmpty, periods, children, summations } =
    useGetRevenueForecast({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  const rows: IRevenueForecastItem[] = [...children];
  let isBeforeCurrentMonth = true;

  const sharedColPeriodSettings: Omit<GridColDef, 'field'> = {
    hideable: false,
    minWidth: 135,
    type: 'number',
    headerAlign: 'right',
    valueFormatter: (value) => currencyFormatter(currentLanguage).format(value ?? 0),
    cellClassName: dataGridCellFontFamilyMonospaceClassName,
  };

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      hideable: false,
      headerName: t('TableHeaderProjectName'),
      minWidth: 175,
      colSpan: (value, row) => {
        if (row?.id === 'TOTAL' || row?.id === 'TOTAL_ACCUMULATED') {
          return 4;
        }
        return undefined;
      },
      valueGetter: (value, row) => {
        if (row?.id === 'TOTAL') {
          return t('TableRowTotal');
        }
        if (row?.id === 'TOTAL_ACCUMULATED') {
          return t('TableRowTotalAccumulated');
        }
        return value;
      },
    },
    { field: 'customer', headerName: t('TableHeaderCustomer'), minWidth: 175 },
    {
      field: 'projectManager',
      headerName: t('TableHeaderProjectManager'),
      minWidth: 175,
    },
    {
      field: 'currency',
      headerName: t('TableHeaderCurrency'),
      minWidth: 80,
    },

    ...periods
      .map((period: IRevenueForecastPeriod, index: number) => {
        let currentMonthColumns: GridColDef[] = [];

        if (period.isCurrentMonth) {
          isBeforeCurrentMonth = false;
          currentMonthColumns = [
            {
              field: `${period.identifier}Realized`,
              headerName: t('TableHeaderRealizedRevenue'),
              description: t('TableHeaderRealizedRevenueDescription'),
              valueGetter: (value, row: IRevenueForecastItem) =>
                row?.values[period.identifier].realized,
              ...sharedColPeriodSettings,
              headerAlign: 'right',
            },
            {
              field: `${period.identifier}ForecastRestOfMonth`,
              headerName: t('TableHeaderRemainingEstimatedRevenue'),
              description: t('TableHeaderRemainingEstimatedRevenueDescription'),
              valueGetter: (value, row: IRevenueForecastItem) =>
                row?.values[period.identifier].forecastRestOfTheMonth,
              ...sharedColPeriodSettings,
              headerAlign: 'right',
            },
            {
              field: `${period.identifier}ForecastMonth`,
              headerName: t('TableHeaderEstimatedMonthlyRevenue'),
              description: t('TableHeaderEstimatedMonthlyRevenueCurrentMonthDescription'),
              valueGetter: (value, row: IRevenueForecastItem) =>
                row?.values[period.identifier].forecast,
              headerClassName: `${dataGridCellBorderRightThickClassName}`,
              ...sharedColPeriodSettings,
              cellClassName: `${dataGridCellBorderRightThickClassName} ${sharedColPeriodSettings.cellClassName}`,
            },
          ];
          return [...currentMonthColumns];
        }

        return {
          field: `${period.identifier}Realized`,
          headerName: isBeforeCurrentMonth
            ? t('TableHeaderRealizedMonthlyRevenue')
            : t('TableHeaderEstimatedMonthlyRevenue'),
          description: isBeforeCurrentMonth
            ? t('TableHeaderRealizedRevenueDescription')
            : t('TableHeaderEstimatedMonthlyRevenueFutureMonthDescription'),
          valueGetter: (value: any, row: IRevenueForecastItem): number =>
            row?.values[period.identifier].realized ?? row?.values[period.identifier].forecast,
          headerClassName: `${dataGridCellBorderRightThickClassName} ${
            index === 0 && dataGridCellBorderLeftThickClassName
          }`,
          ...sharedColPeriodSettings,
          cellClassName: `${
            sharedColPeriodSettings.cellClassName
          } ${dataGridCellBorderRightThickClassName} ${
            index === 0 && dataGridCellBorderLeftThickClassName
          }`,
        };
      })
      .flat(),
    {
      field: 'totalRealized',
      type: 'number',
      valueFormatter: (value) => currencyFormatter(currentLanguage).format(value ?? 0),
      cellClassName: dataGridCellFontFamilyMonospaceClassName,
      headerName: t('TableHeaderTotalRealizedRevenue'),
      description: t('TableHeaderTotalRealizedRevenueDescription'),
      minWidth: 100,
    },
    {
      field: 'totalForecast',
      type: 'number',
      valueFormatter: (value) => currencyFormatter(currentLanguage).format(value ?? 0),
      headerName: t('TableHeaderTotalEstimatedRevenue'),
      description: t('TableHeaderTotalEstimatedRevenueDescription'),
      minWidth: 100,
      cellClassName: dataGridCellFontFamilyMonospaceClassName,
    },
    {
      field: 'total',
      hideable: false,
      type: 'number',
      headerName: t('TableHeaderTotal'),
      minWidth: 100,
      headerClassName: dataGridCellBorderLeftThickClassName,
      cellClassName: ({ id }) => {
        const classNames = `${dataGridCellFontWeightMediumClassName} ${dataGridCellFontFamilyMonospaceClassName}`;
        if (id === 'TOTAL') {
          return `${classNames} ${dataGridCellBorderLeftThickClassName}`;
        }

        return classNames;
      },
      renderCell: ({ value, id }) => {
        if (id === 'TOTAL') {
          return (
            <Typography fontWeight={700} variant="numeric">
              {currencyFormatter(currentLanguage).format(value ?? 0)}
            </Typography>
          );
        }

        return currencyFormatter(currentLanguage).format(value ?? 0);
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'informationGroup',
      headerName: '',
      headerAlign: 'center',
      children: [
        { field: 'projectName' },
        { field: 'customer' },
        { field: 'projectManager' },
        { field: 'currency' },
      ],
    },
    ...periods.map((period): GridColumnGroup => {
      const { isCurrentMonth } = period;
      const periodFields = [{ field: `${period.identifier}Realized` }];
      const usedFields = [
        ...periodFields,
        { field: `${period.identifier}ForecastRestOfMonth` },
        { field: `${period.identifier}ForecastMonth` },
      ];

      return {
        groupId: `${period.identifier}Group`,
        headerName: `${shortMonthAndYearTranslated(new Date(period.startsAt), currentLanguage)} ${
          isCurrentMonth ? ` (${t('TableGroupHeaderCurrentMonth')})` : ''
        }`,
        description: longMonthAndYearTranslated(new Date(period.startsAt), currentLanguage),
        headerAlign: 'center',
        children: isCurrentMonth ? usedFields : periodFields,
      };
    }),
    {
      groupId: 'totalGroup',
      headerName: t('TableGroupHeaderTotal'),
      headerAlign: 'center',
      description: '',
      children: [{ field: 'totalRealized' }, { field: 'totalForecast' }, { field: 'total' }],
    },
  ];

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...summations],
  };

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef]);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={isEmpty} isError={isError}>
      <div
        style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroupingModel}
          pinnedRows={summations.length > 0 ? pinnedRows : undefined}
          disableColumnMenu
          slots={slots}
          disableRowSelectionOnClick
          pinnedColumns={{ left: ['projectName'], right: ['total'] }}
          hideFooter
          sx={{
            // Allow long text to wrap in the header
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 'normal',
            },
          }}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            if (!Number.isNaN(params.value) && params.value < 0) {
              return 'error';
            }

            return '';
          }}
          data-automation-id="RevenueForecastTable"
        />
      </div>
    </ResponseHandler>
  );
};
