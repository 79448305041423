import { v4 as uuidv4 } from 'uuid';

// Expected data with inner children
const itemsWithChild: any = [
  {
    id: uuidv4(),
    name: '_TIM - WIP Report 2024',
    customerNo: 'P17.0009',
    customer: 'TimeLog A/S',
    contractTotal: 25000,
    endDate: '10-05-2024',
    children: [
      {
        id: uuidv4(),
        name: 'Contract Name',
        customerNo: '',
        contractTotal: 25000,
        endDate: '',
        children: [
          {
            id: uuidv4(),
            name: 'Own work',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 1000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'External work',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Goods',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Expenses',
            customerNo: 'K210013',
            contractTotal: 6250,
            endDate: '',
            primoBar: 4000,
            primoInvoiced: 2000,
            primoAdjustment: 6000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
    ],
    primoBar: '10000',
    primoInvoiced: '11000',
    primoAdjustment: '9000',
    primoWip: '44000',
    estNbs: '40000',
    bar: '4000',
    invoiced: '40000',
    adjustment: '36000',
    wip: '36000',
    ultimoBar: '36000',
    ultimoInvoiced: '40000',
    ultimoAdjustment: '34000',
    ultimoWip: '44000',
  },
  {
    id: uuidv4(),
    name: '_TIM - Search project 2024',
    customerNo: 'P23.0009',
    customer: 'TimeLog A/S',
    contractTotal: 18750,
    endDate: '11-05-2024',
    children: [
      {
        id: uuidv4(),
        name: 'Contract Name 2',
        customerNo: '',
        contractTotal: 18750,
        endDate: '',
        children: [
          {
            id: uuidv4(),
            name: 'External work',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Goods',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Expenses',
            customerNo: 'K210013',
            contractTotal: 6250,
            endDate: '',
            primoBar: 4000,
            primoInvoiced: 2000,
            primoAdjustment: 6000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
    ],
    primoBar: '10000',
    primoInvoiced: '10000',
    primoAdjustment: '10000',
    primoWip: '10000',
    estNbs: '10000',
    bar: '10000',
    invoiced: '10000',
    adjustment: '10000',
    wip: '10000',
    ultimoBar: '10000',
    ultimoInvoiced: '10000',
    ultimoAdjustment: '10000',
    ultimoWip: '10000',
  },
  {
    id: uuidv4(),
    name: '_TIM - Epic work 2024',
    customerNo: 'P17.0009',
    customer: 'DigitalTrust A/S',
    contractTotal: 10000,
    endDate: '22-02-2024',
    children: [
      {
        id: uuidv4(),
        name: 'Contract Name',
        customerNo: '',
        contractTotal: 10000,
        endDate: '',
        children: [
          {
            id: uuidv4(),
            name: 'Own work',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 1000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'External work',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Goods',
            customerNo: 'K210012',
            contractTotal: 6250,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Expenses',
            customerNo: 'K210013',
            contractTotal: 6250,
            endDate: '',
            primoBar: 4000,
            primoInvoiced: 2000,
            primoAdjustment: 6000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
    ],
    primoBar: '10000',
    primoInvoiced: '11000',
    primoAdjustment: '9000',
    primoWip: '44000',
    estNbs: '40000',
    bar: '4000',
    invoiced: '40000',
    adjustment: '36000',
    wip: '36000',
    ultimoBar: '36000',
    ultimoInvoiced: '40000',
    ultimoAdjustment: '34000',
    ultimoWip: '44000',
  },
  {
    id: uuidv4(),
    name: '_TIM - Epic 999',
    customerNo: 'P23.0009',
    customer: 'DigitalTrust A/S',
    contractTotal: 20000,
    endDate: '30-09-2024',
    children: [
      {
        id: uuidv4(),
        name: 'Contract Name 2',
        customerNo: '',
        contractTotal: 20000,
        endDate: '',
        children: [
          {
            id: uuidv4(),
            name: 'External work',
            customerNo: 'K210012',
            contractTotal: 5000,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Goods',
            customerNo: 'K210012',
            contractTotal: 5000,
            endDate: '',
            primoBar: 2000,
            primoInvoiced: 3000,
            primoAdjustment: 1000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
          {
            id: uuidv4(),
            name: 'Expenses',
            customerNo: 'K210013',
            contractTotal: 5000,
            endDate: '',
            primoBar: 4000,
            primoInvoiced: 2000,
            primoAdjustment: 6000,
            primoWip: 11000,
            estNbs: 10000,
            bar: 1000,
            invoiced: 10000,
            adjustment: 9000,
            wip: 9000,
            ultimoBar: 9000,
            ultimoInvoiced: 10000,
            ultimoAdjustment: 11000,
            ultimoWip: 11000,
          },
        ],
        primoBar: '',
        primoInvoiced: '',
        primoAdjustment: '',
        primoWip: '',
        estNbs: '',
        bar: '',
        invoiced: '',
        adjustment: '',
        wip: '',
        ultimoBar: '',
        ultimoInvoiced: '',
        ultimoAdjustment: '',
        ultimoWip: '',
      },
    ],
    primoBar: '20000',
    primoInvoiced: '20000',
    primoAdjustment: '20000',
    primoWip: '20000',
    estNbs: '20000',
    bar: '20000',
    invoiced: '20000',
    adjustment: '20000',
    wip: '20000',
    ultimoBar: '20000',
    ultimoInvoiced: '20000',
    ultimoAdjustment: '20000',
    ultimoWip: '20000',
  },
];

export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'Wip',
      viewOptions: {
        'grouped-by': 'group-by-project',
        'reporting-level': 'project',
        'revenue-in-wip': 'bar',
        'period-starts-at': '2023-03-01',
        'period-ends-at': '2023-09-30',
      },
      periods: [
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Month',
          identifier: '2023Mar',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Month',
          identifier: '2023Apr',
        },
        {
          startsAt: '2023-05-01T00:00:00',
          endsAt: '2023-05-31T00:00:00',
          type: 'Month',
          identifier: '2023May',
        },
        {
          startsAt: '2023-06-01T00:00:00',
          endsAt: '2023-06-30T00:00:00',
          type: 'Month',
          identifier: '2023Jun',
          isCurrentMonth: true,
        },
        {
          startsAt: '2023-07-01T00:00:00',
          endsAt: '2023-07-31T00:00:00',
          type: 'Month',
          identifier: '2023Jul',
        },
        {
          startsAt: '2023-08-01T00:00:00',
          endsAt: '2023-08-31T00:00:00',
          type: 'Month',
          identifier: '2023Aug',
        },
        {
          startsAt: '2023-09-01T00:00:00',
          endsAt: '2023-09-30T00:00:00',
          type: 'Month',
          identifier: '2023Sep',
        },
      ],
      children: itemsWithChild,
      summations: [
        {
          id: 'TOTAL',
          // hierarchy: ['TimeLog A/S', 'Project name 3', 'Contract Name', 'Expenses'],
          name: '',
          customerNo: '',
          contractTotal: 46750,
          endDate: '',
          primoBar: 50000,
          primoInvoiced: 42000,
          primoAdjustment: 48000,
          primoWip: 118000,
          estNbs: 118000,
          bar: 38000,
          invoiced: 118000,
          adjustment: 102000,
          wip: 102000,
          ultimoBar: 102000,
          ultimoInvoiced: 110000,
          ultimoAdjustment: 98000,
          ultimoWip: 118000,
        },
      ],
    },
    links: [{ href: '/wip', rel: 'self' }],
  },
  DeterministicHash: 2699400,
  StatusCode: 200,
};
