import { Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridColDef,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';

interface IToolbarProps {
  columns: GridColDef[];
}

export const Toolbar = ({ columns }: IToolbarProps) => (
  <Stack direction="row" justifyContent="flex-end" alignItems="center">
    <GridToolbarContainer>
      {/* <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer> */}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem options={{ disableToolbarButton: true }} />
        <GridExcelExportMenuItem columns={columns} />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  </Stack>
);
