import { GridApi, GridRowModel, GridRowId } from '@mui/x-data-grid-pro';

// To programmatically expand 1 level of the tree using MUI API setRowChildrenExpansion method
export const expandRows = (apiRef: React.MutableRefObject<GridApi>) => {
  const rowIds = apiRef.current.getAllRowIds();

  // Get all row nodes (Row node details)
  const allRowNode = rowIds.map((id: GridRowId) => apiRef.current.getRowNode(id));

  if (allRowNode.length > 0) {
    // Find the maximum depth of expanded rows
    const maxDepth = allRowNode.reduce((max: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && row.childrenExpanded) {
        return Math.max(max, row.depth);
      }
      return max;
    }, -1);

    // Find the minimum depth of collapsed rows
    const minDepth = allRowNode.reduce((min: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && !row.childrenExpanded) {
        return Math.min(min, row.depth);
      }
      return min;
    }, maxDepth);

    // Filter rows that are not leaf nodes, have depth equal to minDepth and are not expanded
    let allShouldExpandRowId = allRowNode.filter(
      (row: GridRowModel | null) =>
        row?.type !== 'leaf' && row?.depth === minDepth && !row?.childrenExpanded,
    );

    // Check if all children are expanded
    const isAllChildExpanded = allRowNode.filter(
      (row: GridRowModel | null) => row?.type !== 'leaf' && !row?.childrenExpanded,
    );

    // If there are no rows to expand and there are children that are not expanded,
    // filter rows that are not leaf nodes, have depth equal to minDepth + 1 and are not expanded
    if (allShouldExpandRowId?.length <= 0 && isAllChildExpanded?.length > 0) {
      allShouldExpandRowId = allRowNode.filter(
        (row: GridRowModel | null) =>
          row?.type !== 'leaf' && row?.depth === minDepth + 1 && !row?.childrenExpanded,
      );
    }

    // Expand all rows in allShouldExpandRowId
    allShouldExpandRowId.forEach((row: GridRowModel | null) => {
      apiRef.current.setRowChildrenExpansion(row?.id, true);
    });
  }
};

// To programmatically collapse 1 level of the tree using MUI API setRowChildrenExpansion method
export const collapseRow = (apiRef: React.MutableRefObject<GridApi>) => {
  const rowIds = apiRef.current.getAllRowIds();

  const allRowNode = rowIds.map((id: GridRowId) => apiRef.current.getRowNode(id));

  if (allRowNode.length > 0) {
    // Find the maximum depth among all row nodes
    const maxDepth = allRowNode.reduce((max: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && row.childrenExpanded) {
        return Math.max(max, row.depth);
      }
      return max;
    }, -1);

    // Find all row nodes that should be collapsed
    const allShouldCollapseRowId = allRowNode.filter(
      (row: GridRowModel | null) => row?.type !== 'leaf' && row?.depth === maxDepth,
    );

    // Collapse each row node that should be collapsed
    allShouldCollapseRowId.forEach((row: GridRowModel | null) => {
      apiRef.current.setRowChildrenExpansion(row?.id, false);
    });
  }
};
