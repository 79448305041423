/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { DataGrid } from 'src/components/mui-components/DataGrid';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import {
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useFilterStore } from 'src/stores/FilterStore';
import { Button, Typography, Box, IconButton } from 'src/components/mui-components';
import { UnfoldMore, UnfoldLess } from '@mui/icons-material';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { currencyFormatter } from 'src/utils/currency';
import { IWipItem, useGetGroupByProject } from 'src/apis/reports/wipAPI';
import { WipSelectedFilterListStateKey } from '../localStorageKeys';
import { Toolbar } from './Toolbar';
import { expandRows, collapseRow } from '../helper/groupingColumnAction';

const slots = (columns: GridColDef[]) => ({
  toolbar: () => <Toolbar columns={columns} />,
});

function massageData(itemsWithChild: any[]): any[] {
  const items: any[] = [];

  function traverseChildren(children: any[], hierarchy: string[]): void {
    children.forEach((child: any) => {
      const item: any = {
        id: child.id,
        hierarchy: [...hierarchy, child.name],
        customer: child.customer,
        customerNo: child.customerNo,
        contractTotal: child.contractTotal,
        endDate: child.endDate,
        primoBar: child.primoBar || '',
        primoInvoiced: child.primoInvoiced || '',
        primoAdjustment: child.primoAdjustment || '',
        primoWip: child.primoWip || '',
        estNbs: child.estNbs || '',
        bar: child.bar || '',
        invoiced: child.invoiced || '',
        adjustment: child.adjustment || '',
        wip: child.wip || '',
        ultimoBar: child.ultimoBar || '',
        ultimoInvoiced: child.ultimoInvoiced || '',
        ultimoAdjustment: child.ultimoAdjustment || '',
        ultimoWip: child.ultimoWip || '',
      };

      items.push(item);

      if (child.children) {
        traverseChildren(child.children, [...hierarchy, child.name]);
      }
    });
  }

  traverseChildren(itemsWithChild, []);

  return items;
}

export const ProjectTable = ({ selectedViewOptions }: { selectedViewOptions: any }) => {
  const { currentLanguage } = useGetCurrentLanguage();
  const { t } = useTranslation('wip');
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(WipSelectedFilterListStateKey, JSON.stringify(filterQueryObj));
  const apiRef = useGridApiRef();

  const { isLoading, isSuccess, isError, isEmpty, periods, children, summations } =
    useGetGroupByProject({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  // Massage to MUI tree data format
  const rows: IWipItem[] = massageData(children);

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'informationGroup',
      headerName: '',
      children: [
        { field: GRID_TREE_DATA_GROUPING_FIELD },
        { field: 'customer' },
        { field: 'customerNo' },
        { field: 'contractTotal' },
        { field: 'endDate' },
      ],
    },
    {
      groupId: 'primo',
      headerName: `${t('TableGroupHeaderPrimo')} (31-03-2024)`,
      headerAlign: 'center',
      children: [
        { field: 'primoBar' },
        { field: 'primoInvoiced' },
        { field: 'primoAdjustment' },
        { field: 'primoWip' },
      ],
    },
    {
      groupId: 'period',
      headerName: `${t('TableGroupHeaderPeriod')} (01-04-2024 - 30-04-2024)`,
      headerAlign: 'center',
      children: [
        { field: 'estNbs' },
        { field: 'bar' },
        { field: 'invoiced' },
        { field: 'adjustment' },
        { field: 'wip' },
      ],
    },
    {
      groupId: 'ultimo',
      headerName: `${t('TableGroupHeaderUltimo')} (30-04-2024)`,
      headerAlign: 'center',
      children: [
        { field: 'ultimoBar' },
        { field: 'ultimoInvoiced' },
        { field: 'ultimoAdjustment' },
        { field: 'ultimoWip' },
      ],
    },
  ];

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...summations],
  };

  const sharedColPeriodSettings: any = {
    valueFormatter: (value: any) =>
      value ? currencyFormatter(currentLanguage).format(value ?? 0) : '',
    width: 110,
    headerAlign: 'right',
    align: 'right',
  };

  const columns: GridColDef[] = [
    { field: 'customer', headerName: t('TableHeaderCustomer'), width: 150 },
    { field: 'customerNo', headerName: t('TableHeaderCustomerNo'), width: 150 },
    {
      field: 'contractTotal',
      headerName: t('TableHeaderContractTotal'),
      ...sharedColPeriodSettings,
    },
    { field: 'endDate', headerName: t('TableHeaderEndDate'), width: 150 },
    {
      field: 'primoBar',
      headerName: t('TableHeaderBar'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'primoInvoiced',
      headerName: t('TableHeaderInvoiced'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'primoAdjustment',
      headerName: t('TableHeaderAdjustments'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'primoWip',
      headerName: t('TableHeaderWip'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'estNbs',
      headerName: t('TableHeaderEstNbs'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'bar',
      headerName: t('TableHeaderBar'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'invoiced',
      headerName: t('TableHeaderInvoiced'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'adjustment',
      headerName: t('TableHeaderAdjustments'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'wip',
      headerName: t('TableHeaderWip'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'ultimoBar',
      headerName: t('TableHeaderBar'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'ultimoInvoiced',
      headerName: t('TableHeaderInvoiced'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'ultimoAdjustment',
      headerName: t('TableHeaderAdjustments'),
      ...sharedColPeriodSettings,
    },
    {
      field: 'ultimoWip',
      headerName: t('TableHeaderWip'),
      ...sharedColPeriodSettings,
    },
  ];

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    hideDescendantCount: true,
    minWidth: 300,
    flex: 1,
    renderHeader: () => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span>
          <IconButton
            aria-label="expand"
            size="small"
            // title={t('UnfoldMore')}
            title="Expand one level"
            onClick={() => {
              expandRows(apiRef);
            }}
          >
            <UnfoldMore fontSize="inherit" />
          </IconButton>
        </span>
        <IconButton
          aria-label="collapse"
          size="small"
          // title={t('UnfoldLess')}
          title="Collapse one level"
          onClick={() => {
            collapseRow(apiRef);
          }}
        >
          <UnfoldLess fontSize="inherit" />
        </IconButton>
        <span style={{ marginLeft: 8 }}>{t('TableHeaderProject')}</span>
      </Box>
    ),
  };

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef]);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={isEmpty} isError={isError}>
      <div
        style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          apiRef={apiRef}
          treeData
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroupingModel}
          pinnedRows={summations.length > 0 ? pinnedRows : undefined}
          slots={slots(columns)}
          disableRowSelectionOnClick
          hideFooter
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          autoHeight
          sx={{
            '& .MuiDataGrid-main': {
              // remove overflow hidden overwise sticky does not work
              overflow: 'unset',
            },
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 49,
              backgroundColor: 'white',
              zIndex: 1,
            },
            '& .MuiDataGrid-virtualScroller': {
              // remove the space left for the header
              marginTop: '0!important',
            },
          }}
          data-automation-id="WipTable"
        />
      </div>
    </ResponseHandler>
  );
};
